// must match helper/role.js on server
export const USER_ROLES = {
  admin: { id: 1, label: "Administrator:in" },
  hr: { id: 2, label: "HR" },
  employee: { id: 3, label: "Mitarbeiter:in" },
  panda: { id: 4, label: "Panda" }
}

export const verifyAdmin = (user) => (
  user && user.role === USER_ROLES.admin.id
)

export const verifyHR = (user) => (
  user && user.role === USER_ROLES.hr.id
)

export const verifyEmployee = (user) => (
  user && user.role === USER_ROLES.employee.id
)

export const verifyPanda = (user) => (
  user && user.role === USER_ROLES.panda.id
)