import React from "react";
import axios from 'axios';
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

import App from "./layout/App";

import "./style/global.scss";

// Setup Axios interceptor
axios.interceptors.response.use(response => {
   return response;
}, error => {
  if (error.response && error.response.status === 401) {
    window.sessionStorage.removeItem("token");
    window.location.href = "/?sessionExpired=true";
}
   return Promise.reject(error);
});

const container = document.getElementById("root");
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <BrowserRouter history={createBrowserHistory()}>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

reportWebVitals();
