import { useEffect, useState, useContext } from 'react';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from "react-bootstrap/Alert";

import { AppContext } from '../../layout/App';

export default function NotificationModal(props) {
  const [data, setData] = useState([]);
  const { setInfoAlert } = useContext(AppContext);

  useEffect(() => {
    if(props.data) { setData(props.data) }
  }, [props.data])

  const removeData = (index) => {
    var newData = [...data]
    newData.splice(index, 1)
    setData(newData)
    if(newData.length < 1) { props.onClose() }
  }

  const onRemoveNotification = (id, index) => {
    axios.post(process.env.REACT_APP_NODE_BACKEND + "/panda/notification/delete", {
      id
    }, { headers: { "Authorization": window.sessionStorage.getItem("token") } })
    .then(res => res.data)
    .then(data => {
      var valid = JSON.parse(data.success)
      if(valid) { removeData(index) }
      else { setInfoAlert({ variant: "danger", message: data.message })}
    })
    .catch(err => { setInfoAlert({ variant: "danger", text: err.message }) })
  }

  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Benachrichtigung
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data && data.map((d, i) => (
          <Alert
            variant="warning"
            className={i >= data.length - 1 ? "mb-0" : ""}
            key={"notification_" + i}
            dismissible
            onClose={() => { removeData(i) }}
          >
            <Alert.Heading>{d["bezeichnung"]}</Alert.Heading>
            {d["inhalt"]}
            <div className="mt-3 d-flex justify-content-start">
              <Button
                onClick={() => onRemoveNotification(d["user_notification_link_id"], i)}
                variant="outline-secondary"
              >
                Nicht mehr anzeigen
              </Button>
            </div>
          </Alert>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onClose}
          variant="outline-mm"
        >
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
}