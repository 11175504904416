import { useState, useContext, useEffect, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BsArrowLeftRight, BsFillPersonDashFill, BsJustifyLeft
} from "react-icons/bs";
import {
  ProSidebar, Menu, MenuItem, SubMenu,
  SidebarHeader, SidebarContent, SidebarFooter
} from 'react-pro-sidebar';
import { AppContext } from "../../layout/App";
import { TYPE } from "../../config/SIDEBAR";
import "./Sidebar.scss";

const BREAKPOINT = 992 // lg

/**
 * Sidebar navigation
 * @returns   {Component}
 */
export default function Sidebar(props) {
  const [collapse, setCollapse] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const { setLoading } = useContext(AppContext)

  useLayoutEffect(() => {
    const localStorageValue = localStorage.getItem("sidebarCollapse")
    if(localStorageValue !== null) {
      try {
        setCollapse(JSON.parse(localStorageValue))
        return
      } catch {
        localStorage.removeItem("sidebarCollapse")
      }
    }
    setCollapse(false)
  }, [])

  useEffect(() => {
    if(collapse === undefined) { return }
    localStorage.setItem("sidebarCollapse", collapse)
  }, [collapse])

  useEffect(() => {
    const handleResize = () => { setWidth(window.innerWidth) }
    window.addEventListener("resize", handleResize)
    // unmount
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const navigate = useNavigate()

  // when sidemenu is open on mobile and click outside sidemenu, this function collapses it
  useEffect(() => {
    const checkClickOutside = (e) => {
      const sidebar = document.getElementsByClassName("pro-sidebar-inner")[0]
      if(!collapse && sidebar && !sidebar.contains(e.target)) {
        setCollapse(true)
      }
    }
    if(!collapse && width <= BREAKPOINT) {
      document.addEventListener("mousedown", checkClickOutside)
    } else {
      document.removeEventListener("mousedown", checkClickOutside)
    }
    // unmount
    return () => document.removeEventListener("mousedown", checkClickOutside)
  }, [width, collapse])

  const submitLogout = () => {
    if(window.confirm("Möchten Sie sich wirklich ausloggen?")) {
      // remove session storage, reset sidebar
      setLoading(true)
      props.onLogout()
      window.sessionStorage.clear();
      navigate("./")
      setLoading(false)
    }
  }

  return (
    <div id="Sidebar">
      { /* mobile button */ }
      { width <= BREAKPOINT &&
        <div className={`mobile-button p-3 pe-none fixed-bottom`}>
          <div
            onClick={() => setCollapse(!collapse)}
            className="cursor-pointer pe-auto d-inline-flex p-3 text-white bg-dark rounded"
          >
            <BsJustifyLeft />
          </div>
        </div>
      }
      <ProSidebar
        collapsed={collapse}
        toggled={!collapse}
        breakPoint="lg"
      >
        <SidebarHeader>
          <Menu className="bg-dark">
            <MenuItem
              className="pe-none"
              icon={<img className="bg-white p-1 rounded" width={32} height={32} src="logo-mm.svg" alt='logo-mm' />}
            >
              move-ment GmbH
            </MenuItem>
          </Menu>
        </SidebarHeader>
        <SidebarContent>
          <Menu /*iconShape="square"*/>
            { props.elements?.map((item, i) => {
              switch(item.type) {
                case TYPE.SEPARATOR:
                  return (
                    <hr
                      key={"sidebar_item" + i}
                      style={{ borderColor: "rgba(173, 173, 173, 0.8)" }}
                    />
                  )
                case TYPE.ITEM:
                  return (
                    <MenuItem
                      icon={item.icon}
                      key={"sidebar_item" + i}
                    >
                      {item.label}
                      <Link to={item.path} />
                    </MenuItem>
                  )
                case TYPE.SUB:
                  return (
                    <SubMenu
                      key={"sidebar_item" + i}
                      title={item.label}
                      icon={item.icon}
                    >
                      { item.children.map((child, i) => (
                        <MenuItem key={"child__" + i}>
                          { child.label }
                          <Link to={child.path} />
                        </MenuItem>
                      ))}
                    </SubMenu>
                  )
                default: return null // nothing
              }
            })}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu>
            <MenuItem
              icon={<BsFillPersonDashFill />}
              onClick={submitLogout}
            >
              Logout
            </MenuItem>
            <hr style={{ borderColor: "rgba(173, 173, 173, 0.2)" }} />
            <MenuItem
              icon={<BsArrowLeftRight />}
              onClick={() => setCollapse(!collapse)}
            >
              Minimieren
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    </div>
  )
}