import React from "react";

const PageUserManagement = React.lazy(() => import("../page/admin/PageUserManagement"));
const PageLogin = React.lazy(() => import("../page/credentials/PageLogin"));
const Page404 = React.lazy(() => import("../page/Page404"));
const PagePanda = React.lazy(() => import("../page/panda/PagePanda"));
const PagePassword = React.lazy(() => import("../page/credentials/PagePassword"));
const PageMonthFinish = React.lazy(() => import("../page/admin/PageMonthFinish"));
const PagePFT = React.lazy(() => import("../page/admin/PagePFT"));
const PageYearFinish = React.lazy(() => import("../page/admin/PageYearFinish"));

/**
 * Page routing for the whole application - routes are rendered @ src/index.js
 * = = = = = = = = = = = = = = = = = = = = = = = = = = =
 * - path: path that follows after ip-address or domain
 * - component: which component to render for the given path
 */
export const ROUTES = [
  {
    path: "/404",
    component: <React.Suspense fallback={<></>}><Page404 /></React.Suspense>,
    sidebar: false
  },
  {
    path: "/",
    exact: true,
    component: <React.Suspense fallback={<></>}><PageLogin /></React.Suspense>,
    sidebar: false
  },
  {
    path: "/password",
    component: <React.Suspense fallback={<></>}><PagePassword /></React.Suspense>,
    sidebar: false
  },
  {
    path: "/panda",
    component: <React.Suspense fallback={<></>}><PagePanda /></React.Suspense>,
    sidebar: true
  },
  {
    path: "/usermanagement",
    component: <React.Suspense fallback={<></>}><PageUserManagement /></React.Suspense>,
    sidebar: true
  },
  {
    path: "/monthfinish",
    component: <React.Suspense fallback={<></>}><PageMonthFinish /></React.Suspense>,
    sidebar: true
  },
  {
    path: "/project",
    component: <React.Suspense fallback={<></>}><PagePFT /></React.Suspense>,
    sidebar: true
  },
  {
    path: "/yearfinish",
    component: <React.Suspense fallback={<></>}><PageYearFinish /></React.Suspense>,
    sidebar: true
  }
];