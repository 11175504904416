import Card from "react-bootstrap/Card";

/**
 * 404 page
 * @returns   {Component} 
 */
export default function Page404() {
  return (
    <div
      id="404"
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ position: "absolute", inset: "0" }}
    >
      <Card className="mt-3 mb-3">
        <Card.Header><b>404</b></Card.Header>
        <Card.Body>
          Die gesuchte Seite konnte nicht gefunden werden.
        </Card.Body>
      </Card>
    </div>
  );
}