import { useState, useEffect, createContext } from "react"
import axios from "axios";
import { useLocation, useNavigate, Routes, Route } from "react-router-dom";

import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";

import NotificationModal from "../component/common/NotificationModal";
import Sidebar from "../component/navigation/Sidebar"
import Page404 from "../page/Page404";
import { verifyAdmin } from "../helper/role";

import { ROUTES } from "../config/ROUTES";
import { SIDEBAR } from "../config/SIDEBAR";

import "./App.scss";

// global states
export const AppContext = createContext();

const NON_AUTH_PATHS = ["/password"]

/**
 * Main application
 * @returns     {Component}
 */
export default function App() {
  const [infoAlert, setInfoAlert] = useState({ variant: null, message: null })
  const [loading, setLoading] = useState(true)
  const [notification, setNotification] = useState(null)
  const [sidebar, setSidebar] = useState([])

  const location = useLocation()
  const navigate = useNavigate()

  // authentication
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionExpired = urlParams.get('sessionExpired');

    if (sessionExpired) {
        setInfoAlert({ variant: "danger", message: "Ihre Sitzung ist abgelaufen! Bitte melden Sie sich erneut an." });
    }
    // skip authentication on NON_AUTH_PATHS locations
    if(!NON_AUTH_PATHS.some(path => location.pathname === path)) {
      // check for auth token
      if(window.sessionStorage.getItem("token")) {
        axios.get(process.env.REACT_APP_NODE_BACKEND + "/auth/", {
          headers: { "Authorization": window.sessionStorage.getItem("token") }
        })
        .then(res => res.data)
        // if token valid, function below will be executed
        .then(data => {
          // set sidebar elements according to role
          setSidebar(verifyAdmin(data) ? SIDEBAR.ADMIN : SIDEBAR.USER)
          // refresh token
          if(data.token) { window.sessionStorage.setItem("token", data.token) }
          if(location.pathname === "/") { navigate("/panda") }
          // notifications
          axios.get(process.env.REACT_APP_NODE_BACKEND + "/panda/notification/get", {
            headers: { "Authorization": window.sessionStorage.getItem("token") }
          })
          .then(res => res.data)
          .then(data => {
            var valid = JSON.parse(data.success)
            if(valid && data.content && data.content.length > 0) {
              setNotification(data.content)
            }
          })
          .catch(err => { setInfoAlert({ variant: "danger", message: "Es konnte kein Anmeldetoken gefunden werden! Bitte melden Sie sich erneut an." }) })
        })
        .catch(err => {
          setInfoAlert({ variant: "danger", message: "Ihre Sitzung ist abgelaufen! Bitte melden Sie sich erneut an." })
          window.sessionStorage.removeItem("token")
          if(location.pathname !== "/") { navigate("/") }
        })
      } else {
        navigate("/")
      }
    }
    setLoading(false)
  }, [location.pathname])

  useEffect(() => {
    if(infoAlert.message) {
      const timer = setTimeout(() => setInfoAlert({ variant: null, message: null}), 5000)
      return () => clearTimeout(timer)
    }
  }, [infoAlert])

  const getSidebar = () => {
    let route = ROUTES.find(x => x.path === location.pathname)
    if(route && route.sidebar) {
      return (
        <Sidebar
          elements={sidebar}
          onLogout={() => setSidebar([])}
        />
      )
    }
  }

  return (
    <div id="App" className="w-100 h-100 d-flex flex-row">
      <AppContext.Provider value={{ setInfoAlert, setLoading }}>
        { getSidebar() }
        <div className="flex-grow-1 overflow-auto">
          <Container
            fluid="xxxl"
            className={`h-100${loading ? " opacity-75 pe-none" : ""}`}
          >
            <div className="p-3 w-100 h-100">
              <Routes>
                { ROUTES.map((route, i) => (
                  <Route key={"route" + i} path={route.path} element={route.component} />
                ))}
                <Route path="*" element={<Page404 />} />
              </Routes>
            </div>
          </Container>
        </div>

        <NotificationModal
          show={notification !== null}
          onClose={() => setNotification(null)}
          data={notification}
        />

        <Alert
          dismissible
          onClose={() => setInfoAlert({ variant: null, message: null })}
          className="alert-fixed mt-3 mx-3 mx-lg-5"
          variant={infoAlert.variant || undefined}
          show={infoAlert.message}
        >
          { infoAlert.message || "Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut." }
        </Alert>

        { loading && // loading spinner
          <div className="loading-spinner">
            <Spinner animation="border" variant="mm" />
          </div>
        }
      </AppContext.Provider>
    </div>
  );
}