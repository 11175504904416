import {
  BsFillCalendarEventFill,
  BsFillPeopleFill,
  /* BsColumnsGap, */
  BsFillCalendarMonthFill,
  BsFillDiagram3Fill,
  BsFillCalendarCheckFill
} from "react-icons/bs";

/**
 * sidebar item types, each type represents a different component
 */
export const TYPE = {
  ITEM: "MENU_ITEM",
  SEPARATOR: "MENU_SEPARATOR",
  SUB: "SUB_MENU"
}

/**
 * sidebar items to be rendered in /src/component/navigation/Sidemenu.js
 * = = = = = = = = = = = = = = = = = = = = = = = = = = =
 * - type: type of sidemenu item
 * 
 * - icon: item icon
 * - label: item label
 * 
 * - (TYPE.ITEM) path: path to redirect a sidemenu item (only for TYPE.ITEM)
 * 
 * - (TYPE.SUB) children: array of sub menu items
 *   - label: sub menu item label
 *   - path: sub menu item path
 */
export const SIDEBAR = {
  USER: [
    /*
    {
      type: TYPE.ITEM,
      icon: <BsColumnsGap />,
      label: "Dashboard",
      path: "/dashboard"
    },
    */
    {
      type: TYPE.ITEM,
      icon: <BsFillCalendarEventFill />,
      label: "Zeiterfassung",
      path: "/panda"
    }
  ],
  ADMIN: [
    {
      type: TYPE.ITEM,
      icon: <BsFillCalendarEventFill />,
      label: "Zeiterfassung",
      path: "/panda"
    },
    { type: TYPE.SEPARATOR },
    {
      type: TYPE.ITEM,
      icon: <BsFillPeopleFill />,
      label: "Benutzerverwaltung",
      path: "/usermanagement"
    },
    {
      type: TYPE.ITEM,
      icon: <BsFillDiagram3Fill />,
      label: "PFT",
      path: "/project"
    },
    {
      type: TYPE.ITEM,
      icon: <BsFillCalendarMonthFill />,
      label: "Monatsabschluss",
      path: "/monthfinish"
    },
    {
      type: TYPE.ITEM,
      icon: <BsFillCalendarCheckFill />,
      label: "Jahresabschluss",
      path: "/yearfinish"
    }
  ]
}